<template>
  <div class="performer border border-black rounded">
    <h4>Информация о водителе</h4>
    <div v-if="isActive">
      <p @click="getPerformen()" class="orderColor">
        ФИО: {{ performerExecutor.first_name + " " + performerExecutor.last_name + " " + performerExecutor.patronymic }}
      </p>
      <p>Логин: {{ performerExecutor.login }}</p>
      <p>Телефон: {{ performerExecutor.phone }}</p>
      <p>Статус: {{ performerExecutor.status }}</p>
      <p>Автомобиль: {{ performerExecutor.car_info }}</p>
      <p>Год выпуска: {{ performerExecutor.car_year }}</p>
      <p>Класс авто: {{ performerExecutor.car_class }}</p>
      <p>Категория авто: {{ performerExecutor.car_category }}</p>
      <p>Подразделение: {{ performerExecutor.division }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isActive", "performers", "performerExecutor"],
  methods: {
    getPerformen() {
      this.$router.push({ name: "carInfo", query: { filter: this.performers.id } });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Автомобиль " + this.performers.id,
        `/driver/carInfo`,
        this.performers.id,
      ]);
    },
  },
};
</script>

<style scoped>
.performer {
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 10px;
}
.orderColor {
  color: blue;
  font-size: 15px;
  cursor: pointer;
}
</style>
